<template>
  <v-dialog
    v-model="showDialog"
    fullscreen
    v-if="$vuetify.breakpoint.smAndDown"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        class="text-capitalize action-btn-mobile"
        :depressed="depressedBtn"
        height="32"
        outlined
      >
        <span class="trigger d-flex">
          <template v-if="triggerIcon">
            <v-img
              :src="triggerIcon"
              class="mr-1"
              width="13"
              height="13"
              :alt="$t('rating')"
            />
          </template>
          {{ triggerText }}
        </span>
      </v-btn>
    </template>
    <v-sheet :elevation="0" color="white" class="fill-height">
      <v-container class="pt-1">
        <v-row justify="space-between">
          <v-col cols="1" class="align-self-center" justify-self="start">
            <v-btn class="ml-3" icon @click="onClose">
              <v-icon>mdi-close</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="auto" class="align-self-center" justify-self="center">
            <slot name="title">
              <span class="title">{{ title }}</span>
            </slot>
          </v-col>
          <v-col cols="1" />
        </v-row>
        <v-row no-gutters>
          <v-col cols="12">
            <slot> default content goes here </slot>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid class="pt-0 pr-3 additional-buttons">
        <v-row align="start" justify="space-between" no-gutters>
          <v-btn
            text
            outlined
            color="var(--v-grey7-base)"
            width="124"
            height="48"
            @click.native="onClear"
          >
            {{ $t('clear') }}
          </v-btn>
          <v-btn
            @click="onSubmit"
            color="primary"
            class="btn-submit-filter"
            width="124"
            height="48"
          >
            {{ $t('apply') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-sheet>
  </v-dialog>
  <v-menu
    v-else
    :close-on-content-click="false"
    :nudge-width="200"
    min-height="200"
    offset-y
    v-model="showMenu"
    nudge-bottom="9"
    :min-width="320"
    z-index="0"
  >
    <template v-slot:activator="{ on }">
      <v-btn
        v-on="on"
        :small="$vuetify.breakpoint.mdAndDown"
        outlined
        :class="[
          'text-capitalize',
          $vuetify.breakpoint.mdAndUp ? 'action-btn' : 'action-btn-mobile',
          { 'active-btn': selected },
        ]"
        :depressed="depressedBtn"
        height="32"
      >
        <template v-if="triggerIcon">
          <v-img
            :src="triggerIcon"
            class="mr-1"
            width="13"
            height="13"
            :alt="$t('rating')"
          />
        </template>
        {{ triggerText }}
      </v-btn>
    </template>
    <v-sheet :elevation="0" color="white">
      <v-container class="pa-0">
        <v-row no-gutters>
          <v-col cols="12">
            <slot> default content goes here </slot>
          </v-col>
        </v-row>
      </v-container>
      <v-container fluid :class="['pa-3', isDiscoveryPage && 'sticky-bottom']">
        <v-row
          align="start"
          justify="space-between"
          no-gutters
          :class="[
            'additional-buttons',
            { 'discovery-filter': isDiscoveryPage },
          ]"
        >
          <v-btn
            text
            outlined
            color="var(--v-grey7-base)"
            class="btn-reset-filter"
            width="158"
            height="48"
            @click.native="onClear"
          >
            {{ $t('clear') }}
          </v-btn>
          <v-btn
            @click="onSubmit"
            depressed
            :disabled="disabledApply"
            color="primary"
            class="btn-submit-filter"
            :width="!isDiscoveryPage ? 124 : 158"
            height="48"
          >
            {{ $t('apply') }}
          </v-btn>
        </v-row>
      </v-container>
    </v-sheet>
  </v-menu>
</template>

<script>
export default {
  name: 'PopoverModal',
  props: {
    title: {
      default: 'Price',
      type: String,
    },
    triggerText: {
      default: 'Price Range',
      type: String,
    },
    triggerIcon: {
      default: '',
      type: String,
    },
    closeOnClear: {
      default: false,
      type: Boolean,
    },
    selected: {
      default: false,
      type: Boolean,
    },
    depressedBtn: {
      default: false,
      type: Boolean,
    },
    isDiscoveryPage: {
      default: false,
      type: Boolean,
    },
    primaryColor: String,
    disabledApply: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      showDialog: false,
      showMenu: false,
    };
  },
  methods: {
    onSubmit() {
      this.$emit('submit');
      this.close();
    },
    onClear() {
      this.$emit('clear');
      if (this.closeOnClear) {
        this.close();
      }
    },
    close() {
      this.showDialog = false;
      this.showMenu = false;
    },
    onClose() {
      this.$emit('close');
      this.close();
    },
  },
  watch: {
    showMenu: {
      handler(val) {
        if (val === false) {
          this.$emit('close');
        }
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.action-btn {
  background: #ffffff;
  border: 1px solid #cfd5e0;
  border-radius: 26px !important;
  font-weight: 400;
  font-size: 16px !important;
  line-height: 1.2;

  &-mobile {
    font-size: 12px;
  }
}
.action-btn .primary {
  color: #fff;
}

.action-btn >>> .v-btn__content {
  line-height: 1.2;
}

.additional-buttons .v-btn--text,
.additional-buttons .btn-submit-filter {
  font-weight: 500 !important;
  font-size: 16px;
  line-height: 24px;
  text-transform: capitalize;
}
.additional-buttons .btn-submit-filter {
  border-radius: 8px;
  color: #fff;
}

.additional-buttons {
  border-radius: 8px;
  color: #fff;

  &.discovery-filter {
    & .btn-reset-filter {
      border: 2px solid #eee !important;
    }

    &
      .v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: #fff !important;
      background-color: #d1d1d1 !important;
    }
  }
}

.active-btn {
  color: var(--v-primary-base);
  border-color: var(--v-primary-base);
  font-weight: 500;
}
</style>
